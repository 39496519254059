<template>
  <q-form ref="editForm">
    <c-card title="LBLDETAIL" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="selfCheck"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :editable="editable"
                :disabled="disabled"
                :required="true"
                label="점검명(대상 공정명)"
                name="selfCheckName"
                v-model="selfCheck.selfCheckName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-datepicker
                :required="true"
                :disabled="disabled"
                :editable="editable && !deleteable"
                type="month"
                default="today"
                label="점검년월"
                name="selfCheckMonth"
                v-model="selfCheck.selfCheckMonth"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-dept
                :required="true"
                :disabled="disabled"
                :editable="editable && !deleteable"
                label="주관부서"
                name="deptCd"
                v-model="selfCheck.deptCd">
              </c-dept>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <c-plant
                :required="true"
                :disabled="disabled"
                :editable="editable"
                type="edit" 
                name="plantCd" 
                v-model="selfCheck.plantCd" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-table
            ref="ftable"
            class="q-mt-sm"
            title="대상 시설"
            tableId="ftable"
            :editable="editable&&!disabled"
            :columns="ftable.columns"
            :data="selfCheck.facilities"
            :gridHeight="ftable.height"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            selection="multiple"
            rowKey="facilityCd"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable&&!disabled" :showLoading="false" label="제외" icon="remove" @btnClicked="removeFacility" />
                <c-btn v-if="editable&&!disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addFacility" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </template>
    </c-card>

    <c-table
      v-show="popupParam.selfCheckMonthId"
      ref="table"
      class="q-mt-sm"
      title="점검결과 목록"
      tableId="selfCheckItemResult"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      @headLinkClick="headLinkClick"
    >
      <!-- gridHeight="550px" -->
      <template slot="suffixTitle">
        <font class="text-negative" style="font-size:0.8em;font-weight:300;">
          <div>
          1. 비고란에는 자체점검 시 조치완료된 사항 또는 재점검이 필요한 사항을 적습니다.
          </div>
          <div>
          2. 유해화학물질 취급시설 자체점검을 하려는 자는 양식의 점검 항목이 모두 포함된 별도의 서식을 사용할 수 있으며, 점검 항목이 모두 포함되어 있음을 명확하게 알 수 있도록 표기해야 합니다.)
          </div>
        </font>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-facility-self-check-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          selfCheckMonthId: '',
          isApprContent: true,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      selfCheck: {
        selfCheckMonthId: '',  // 유해화학자재 취급시설 자체점검 월별 일련번호
        plantCd: null,  // 사업장코드
        selfCheckName: '',  // 점검명
        selfCheckMonth: '',  // 자체점검대상 월
        deptCd: '',  // 주관부서 코드
        hcfSelfCheckCompleteFlag: 'N',  // 점검완료여부
        facilities: [], // 대상 시설
      },
      grid: {
        columns: [],
        data: [],
        height: '600px'
      },
      ftable: {
        columns: [
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '200px'
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      printUrl: '',
      detailUrl: '',
      itemResultUrl: '',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isSave: false,
      isComplete: false,
      mappingType: 'POST',
      haederUrl: '',
      checkUrl: '',
      deleteFUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.selfCheck.hcfSelfCheckCompleteFlag === 'Y'
    },
    deleteable() {
      return this.popupParam.selfCheckMonthId && !this.disabled
    },
    printable() {
      return this.popupParam.selfCheckMonthId
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 220);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.printUrl = selectConfig.env.facilitySelfCheck.print.url
      this.detailUrl = selectConfig.env.facilitySelfCheck.mst.get.url
      this.itemResultUrl = selectConfig.env.facilitySelfCheck.mst.item.url
      this.saveUrl = transactionConfig.env.facilitySelfCheck.mst.insert.url
      this.deleteUrl = transactionConfig.env.facilitySelfCheck.mst.delete.url
      this.deleteFUrl = transactionConfig.env.facilitySelfCheck.mst.facility.url
      this.haederUrl = selectConfig.env.facilitySelfCheck.mst.list.url + '/days'
      this.checkUrl = selectConfig.env.facilitySelfCheck.mst.list.url + '/check'

      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.selfCheckMonthId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {selfCheckMonthId: this.popupParam.selfCheckMonthId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.selfCheck, _result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.getHeader();
        },);
      }
    },
    getHeader() {
      this.grid.columns = [];
      this.$http.url = this.haederUrl;
      this.$http.param = {selfCheckMonth: this.selfCheck.selfCheckMonth, deptCd: this.selfCheck.deptCd}
      this.$http.type = 'GET';
      this.$http.request((_resultdata) => {
        this.grid.columns.push({
          name: 'selfCheckItemName',
          field: 'selfCheckItemName',
          label: '점검항목',
          align: 'left',
          sortable: false,
        });

        this.$_.forEach(_resultdata.data, item => {
          this.grid.columns.push({
            name: 'week' + item.weeks,
            field: 'week' + item.weeks,
            label: item.weeks+'주(' + item.termDt + ')',
            align: 'center',
            style: 'width:200px',
            headerType: 'link',
            headerColor: '#FFC000',
            tdColor: null,
            setHeader: true,
            key: item.hcfHazardFacilitySelfCheckId,
            key1: item.startDt,
            key2: item.endDt,
            sortable: false,
          })
        })

        this.grid.columns.push({
          name: 'remark',
          field: 'remark',
          label: 'LBLREMARK',
          align: 'left',
          type: 'html',
          style: 'width:400px',
          sortable: false,
        });

        this.getDetailItems();
      },);
    },
    getDetailItems() {
      if (this.popupParam.selfCheckMonthId) {
        this.grid.data = [];
        this.$http.url = this.itemResultUrl;
        this.$http.param = {selfCheckMonthId: this.popupParam.selfCheckMonthId, deptCd: this.selfCheck.deptCd, selfCheckMonth: this.selfCheck.selfCheckMonth}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    save() {
      if (this.popupParam.selfCheckMonthId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.env.facilitySelfCheck.mst.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.env.facilitySelfCheck.mst.insert.url
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.checkUrl;
          this.$http.param = {deptCd: this.selfCheck.deptCd, selfCheckMonth: this.selfCheck.selfCheckMonth}
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.popupParam.selfCheckMonthId || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.selfCheck.regUserId = this.$store.getters.user.userId
                  this.selfCheck.chgUserId = this.$store.getters.user.userId

                  this.isSave = !this.isSave
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                '해당부서의 동일한 년월의 자체점검이 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.selfCheckMonthId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    removeSelfCheck() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.selfCheckMonthId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addFacility() {
      this.popupOptions.title = 'LBL0000558'; // 시설 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.selfCheck.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/env/hcf/hazardFacilityPop.vue'}`);
      this.popupOptions.width = '65%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupFaciloty;
    },
    closePopupFaciloty(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.selfCheck.facilities, { facilityCd: item.facilityCd }) === -1) {
            this.selfCheck.facilities.push({
              selfCheckMonthId: this.popupParam.selfCheckMonthId,
              facilityCd: item.facilityCd,
              facilityName: item.facilityName,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeFacility() {
      let selectData = this.$refs['ftable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteFUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.selfCheck.facilities = this.$_.reject(this.selfCheck.facilities, item)
              })
              this.$refs['ftable'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    /* eslint-disable no-unused-vars */
    headLinkClick(props, col, index) {
      this.popupOptions.title = String(col.label) + ' 자체점검'
      this.popupOptions.target = () => import(`${'@/pages/env/hcf/hazardFacilitySelfCheckDetail.vue'}`);
      this.popupOptions.param = {
        hcfHazardFacilitySelfCheckId: col.key,
        startDt: col.key1,
        endDt: col.key2,
      };
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '70%';
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetailItems();
    },
  }
};
</script>